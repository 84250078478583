<template>
  <div class="help-center-entry-wrap">
    <router-link :to="{ name: 'help-center.entry.show', params: { slug: entry.fields.slug } }" class="link-color2">
      <div class="help-center-card-wrap">
        <div class="help-center-card" :style="{
          backgroundImage: `url('${coverImageUrl}')`
        }"></div>
        <div class="help-center-card-title mt-3">
          <h4 class="font-size-regular">{{ entry.fields.title }}</h4>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HelpCenterEntryCard',
  props: ['entry'],
  computed: {
    /**
     * Cover image url
     */
    coverImageUrl () {
      if (this.entry.fields.thumbnail) return this.entry.fields.thumbnail.fields.file.url
      else return this.entry.fields.cover.fields.file.url
    }
  }
}
</script>

<style lang="scss" scoped>
.help-center-card {
  -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
  border: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
}
</style>
