<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <!-- FILTERS -->
      <div class="col-lg-2 mb-4">
        <h3 class="font-size-regular highlighted-color1 mb-3">
          <span>{{ $t('filter_materials') }}</span>
        </h3>
        <div class="default-form d-flex d-lg-block">
          <div v-for="(option, index) in filterOptions" :key="index" class="mb-2 me-3 me-lg-0">
            <div class="styled-checkbox">
              <label :for="`option-${index}`">
                <input v-model="filters" type="checkbox" :id="`option-${index}`" :value="option" />
                <span></span>
                {{ option }}
              </label>
            </div>
          </div>
        </div>
      </div><!-- END FILTERS -->
      <!-- CONTENT -->
      <div class="col-lg-10 px-4">
        <div class="d-flex flex-wrap">
          <div
            class="help-center-entry-item mb-4"
            v-for="(entry, index) in filteredEntries"
            :key="index"
          >
            <HelpCenterEntryCard
              :entry="entry"
            />
          </div>
        </div>
      </div><!-- END CONTENT -->
    </div>
  </div>
</template>

<script>

import HelpCenterEntryCard from '@/components/help-center/HelpCenterEntryCard'

export default {
  name: 'HelpCenterEntryList',
  props: ['entries'],
  components: {
    HelpCenterEntryCard
  },
  data () {
    return {
      filters: []
    }
  },
  computed: {
    /**
     * Filtered entries
     */
    filteredEntries () {
      if (this.filters.length === 0) return this.entries
      return this.entries.filter(item => {
        return this.filters.includes(item.fields.category)
      })
    },
    /**
     * Filter options
     */
    filterOptions () {
      const filters = []
      this.entries.forEach(item => {
        if (!filters.includes(item.fields.category)) filters.push(item.fields.category)
      })
      return filters.sort()
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

.help-center-entry-item {
  @include desktop {
    width: calc( (100% / 3) - 15px);
    margin-right: 15px;
  }
}

</style>
